/*--------------------------------------------------------------
# Buttons
--------------------------------------------------------------*/
.button {
	background: $color-secondary;
	color: #fff;
	padding: 15px 20px;
	width: 100%;
	float: left;
	text-transform: uppercase;
	font-weight: bold;
	border-radius: 5px;
	text-shadow:0px 2px 1px rgba(0, 0, 0, 0.1);
	cursor: pointer;
	text-align: center;
	transition: all 0.5s ease;

	&.checkout {
		background: $color-alt;

		&:hover {
			background: darken($color-alt, 5);
		}
	}

	.fas {
		margin-left: 20px;
		transition: all 0.5s ease;
	}

	&:hover {
		box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
		background: darken($color-secondary, 5);

		.fas {
			margin-left: 10px;
		}
	}

	&.pulse {
		animation: pulse 2s infinite cubic-bezier(0.66, 0, 0, 1);
		box-shadow: 0 0 3px 0 rgba(130, 181, 65, 0.3);

		@keyframes pulse {to {box-shadow:0 0 3px 25px rgba(130, 181, 65, 0);}}
	}
}