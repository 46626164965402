/*! Pushy - v1.3.0 - 2019-6-25
* Pushy is a responsive off-canvas navigation menu using CSS transforms & transitions.
* https://github.com/christophery/pushy/
* by Christopher Yee */
/* Menu Appearance */
.menu-btn {
  font-weight: bold;
  color: $color-secondary;
}
.pushy {
  position: fixed;
  width: 250px;
  height: 100%;
  top: 0;
  z-index: 9999;
  background: #fff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  /* enables momentum scrolling in iOS overflow elements */
}
.pushy a {
  display: block;
  color: #333033;
  padding: 15px 30px;
  font-weight: bold;
  text-decoration: none;
}
.pushy a:hover {
  color: $color-secondary;
}
.pushy ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
}
.pushy ul li {
  border-bottom: 1px solid #eee;
}

.pushy ul:first-child {
  margin-top: 10px;
}
.pushy.pushy-left {
  left: 0;
}
.pushy.pushy-right {
  right: 0;
}

.pushy-content {
  visibility: hidden;
}

/* Menu Movement */
.pushy-left {
  -webkit-transform: translate3d(-250px, 0, 0);
  -ms-transform: translate3d(-250px, 0, 0);
  transform: translate3d(-250px, 0, 0);
}

.pushy-open-left #container,
.pushy-open-left .push {
  -webkit-transform: translate3d(250px, 0, 0);
  -ms-transform: translate3d(250px, 0, 0);
  transform: translate3d(250px, 0, 0);
}

.pushy-right {
  -webkit-transform: translate3d(250px, 0, 0);
  -ms-transform: translate3d(250px, 0, 0);
  transform: translate3d(250px, 0, 0);
}

.pushy-open-right #container,
.pushy-open-right .push {
  -webkit-transform: translate3d(-250px, 0, 0);
  -ms-transform: translate3d(-250px, 0, 0);
  transform: translate3d(-250px, 0, 0);
}

.pushy-open-left .pushy,
.pushy-open-right .pushy {
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.pushy-open-left .pushy-content,
.pushy-open-right .pushy-content {
  visibility: visible;
}

/* Menu Transitions */
#container,
.pushy,
.push {
  transition: transform 0.2s cubic-bezier(0.16, 0.68, 0.43, 0.99);
}

.pushy-content {
  transition: visibility 0.2s cubic-bezier(0.16, 0.68, 0.43, 0.99);
}

/* Site Overlay */
.site-overlay {
  display: none;
}

.pushy-open-left .site-overlay,
.pushy-open-right .site-overlay {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9998;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-animation: fade 500ms;
  animation: fade 500ms;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* Submenu Appearance */
.pushy-submenu {
  /* Submenu Buttons */
  /* Submenu Icon */
}
.pushy .sub-menu {
  //padding-left: 15px;
  background: #f5f5f5;
  transition: max-height 0.2s ease-in-out, visibility 0.2s ease-in-out;
}
.pushy-submenu > ul .pushy-link {
  transition: opacity 0.2s ease-in-out;
}
.pushy-submenu button {
  width: 100%;
  color: #333033;
  padding: 15px 30px;
  text-align: left;
  font-weight: bold;
  background: transparent;
  border: 0;
}
.pushy-submenu button:hover {
  color: $color-secondary;;
}
.pushy-submenu > a,
.pushy-submenu > button {
  position: relative;
}
.main_mobile .dropdown {
  position: relative;
}
.main_mobile .dropdown a::after,
.main_mobile .dropdown button::after {
  content: '';
  display: block;
  height: 11px;
  width: 8px;
  position: absolute;
  top: 27px;
  right: 15px;
  background: url("../images/arrow.svg") no-repeat;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: transform 0.2s;
}

.main_mobile .dropdown .menu-item a::after {
  display: none;
}

/* Submenu Movement */
.pushy-submenu-closed > ul {
  max-height: 0;
  overflow: hidden;
  visibility: hidden;
}
.pushy-submenu-closed .pushy-link {
  opacity: 0;
}

.pushy-submenu-open {
  /* Submenu Icon */
}
.pushy-submenu-open > ul {
  max-height: 1000px;
  visibility: visible;
}
.pushy-submenu-open .pushy-link {
  opacity: 1;
}
.pushy-submenu-open > a::after,
.pushy-submenu-open > button::after {
  -webkit-transform: translateY(-50%) rotate(90deg);
  -ms-transform: translateY(-50%) rotate(90deg);
  transform: translateY(-50%) rotate(90deg);
}

/*# sourceMappingURL=pushy.css.map */
