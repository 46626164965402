html {
	padding: 0px;
	margin: 0px;
}

body {
	font-size: $base-font-size;
	line-height: 26px;
	color: $color-primary;
	padding: 0px;
	background: $color-white;
	font-family: $base-font-family;
	margin: 0px;

	&.modal_open {
		overflow: hidden;
		height: 100%;
		width: 100%;
	}

	&.admin-bar {
		.wrap-menu-desktop {
			top: 72px;
		}
	}
}

h1, h2, h3, h4, h5, h6 {
	font-family: $font-title;
	font-weight: 300;
}

p a {
	color: $color-secondary;
	text-decoration: underline;
}

.loader {
	width: 100%;
	height: 100%;
	background: #fff;
	position: fixed;
	z-index: 1000;
}

.coupon {
	display: none;
}