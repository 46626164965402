/*--------------------------------------------------------------
# Page style
--------------------------------------------------------------*/
.content_page {
	width: 100%;
	float: left;

	h1, h2, h3, h4, h5, h6 {
		font-size: 21px;
		margin: 0px 0px 5px 0px;
	}

	p {
		margin-bottom: 20px;
	}

	ul {
		list-style-type: disc;
		padding-left: 20px;
		margin: 0px 0px 20px 0px;

		li {
			list-style-type: disc;
		}
	}
}

/*--------------------------------------------------------------
# Responsive page style
--------------------------------------------------------------*/
@include breakpoint(lg) {

}

@include breakpoint(md) {

}

@include breakpoint(sm) {

}