/*--------------------------------------------------------------
# Footer style
--------------------------------------------------------------*/
.footer {
	padding: 20px 0px;

	.copy {
		float: left;
	}

	ul {
		float: right;
		padding: 0px;
		margin: 0px;

		li {
			float: left;
			display: inline-block;
		}

		a {
			color: $color-primary;
			padding: 0px 10px;
		}
	}

}

.footer_bg {
	margin-top: 50px;
	width: 100%;

	//border-top: 1px solid #f1f2f4;

	.stext-301 {
		font-weight: bold;
	}
}

/*--------------------------------------------------------------
# Responsive footer style
--------------------------------------------------------------*/
@include breakpoint(lg) {

}

@include breakpoint(md) {

}

@include breakpoint(sm) {

}