/*--------------------------------------------------------------
# Page style
--------------------------------------------------------------*/
.thankyou {
	max-width: 600px;
	padding: 40px;
	box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
	background: #fff;
	margin:50px auto;
	text-align: center;
	border-bottom: 3px solid $color-secondary;

	.logo {
		background: url(../images/Logo-Premiumhealth-Black.png) center center no-repeat;
		width: 100%;
		height: 70px;
		padding: 0px 20px;
		background-size: auto 60%;
		float: left;
		margin-bottom: 20px;
		position: relative;
	}
}

/*--------------------------------------------------------------
# Responsive page style
--------------------------------------------------------------*/
@include breakpoint(lg) {

}

@include breakpoint(md) {

}

@include breakpoint(sm) {

}